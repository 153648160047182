import React from "react"
import { Link } from "gatsby"
import Head from "../components/head"
import Layout from "../components/layout"
import unusualDemont02 from "../img/unusual-demont-02.jpg"

const NotFound = () => {
  return (
    <Layout>
      <Head title="404" />
      <div style={{ textAlign: "center" }}>
        <h1 style={{ padding: "10px", fontSize: "36pt" }}>
          <Link to="/">Lost?</Link>
        </h1>
        <img
          src={unusualDemont02}
          alt="unusual demont with a mask on, very cool"
          style={{ width: "400px" }}
        />
      </div>
    </Layout>
  )
}

export default NotFound
